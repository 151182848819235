import React, { useEffect, useState } from 'react'
import { Container, Box, HStack, Image, SimpleGrid, Divider, VStack, Icon, Text } from '@chakra-ui/react';
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useCategories } from '../../providers/CategoriesProvider';

export default function Footer() {
    const { categories } = useCategories();

    const [filteredCategories, setfilteredCategories] = useState([]);

    useEffect(() => {
        // get only first 6 categories
        if (categories.length > 6) {
            setfilteredCategories(categories.slice(0, 6));
        } else {
            setfilteredCategories(categories);
        }

    }, [categories])


    return (
        <Box bg='#2b2b2b' color={'white'} style={{ width: '100%' }} alignItems='center' justifyContent={'center'}>
            <Container maxW='8xl' >

                <SimpleGrid columns={[2, 2, 2, 5]} p='6' spacing='8'>

                    <Box>
                        <Image src='/logo-dark.png' width='180px' alt='Vocalthread Logo' />
                    </Box>

                    <VStack w='100%' alignItems='start' spacing='2'>
                        <Text fontSize={['xl', 'xl', '1xl', '1xl']} fontWeight={'bold'} className='custom' color='white'>Categories</Text>

                        {filteredCategories.map((cat, index) => {
                            return (
                                <Link key={index} to={`/categories/${cat.slug}`}>
                                    <Text color="#c4c0c0" cursor='pointer'>{cat.name}</Text>
                                </Link>
                            )
                        })}
                    </VStack>

                    <VStack w='100%' alignItems='start' spacing='2'>
                        <Text fontSize={['xl', 'xl', '1xl', '1xl']} fontWeight={'bold'} className='custom' color='white'>Useful Link</Text>
                        {/* 
                        <Link to='/about'>
                            <Text color='#c4c0c0' cursor='pointer'>About Us</Text>
                        </Link> */}

                        <Link to='/privacy-policy'>
                            <Text color='#c4c0c0' cursor='pointer'>Privacy Policy</Text>
                        </Link>

                        <Link to='/terms-and-conditions'>
                            <Text color='#c4c0c0' cursor='pointer'>Terms & Conditions</Text>
                        </Link>

                        <Link to='/contact'>
                            <Text color='#c4c0c0' cursor='pointer'>Contact Us</Text>
                        </Link>

                        <Link to='/disclaimer'>
                            <Text color='#c4c0c0' cursor='pointer'>Disclaimer</Text>
                        </Link>

                        <Link to='/paymentpolicy'>
                            <Text color='#c4c0c0' cursor='pointer'>Payment Policy</Text>
                        </Link>

                        <Link to='/shipping-and-delivery'>
                            <Text color='#c4c0c0' cursor='pointer'>Shipping and Delivery</Text>
                        </Link>

                    </VStack>

                    <VStack w='100%' alignItems='start' spacing='2'>
                        <Text fontSize={['xl', 'xl', '1xl', '1xl']} fontWeight={'bold'} className='custom' color='white'>Contact</Text>

                        <HStack w='100%'>
                            <Icon as={FaLocationDot} color='#c4c0c0' />
                            <Text color='#c4c0c0'>
                                Bokaro Steel City, Jharkhand
                            </Text>
                        </HStack>

                        <a href='mailto:vocalthread@gmail.com'>
                            <HStack w='100%'>
                                <Icon as={FaEnvelope} color='#c4c0c0' />
                                <Text color='#c4c0c0'>
                                    Email
                                </Text>
                            </HStack>
                        </a>

                    </VStack>

                    <VStack w='100%' alignItems='start' spacing='2'>
                        <Text fontSize={['xl', 'xl', '1xl', '1xl']} fontWeight={'bold'} className='custom' color='white'>Social</Text>

                        <a href='https://www.instagram.com/vocalthread?igsh=MXhrZWs1ZnQxbDcxcA==' target='_blank' rel='noreferrer'>
                            <HStack>
                                <Icon as={FaInstagram} color='#c4c0c0' />
                                <Text color='#c4c0c0' cursor='pointer'>
                                    Instagram
                                </Text>
                            </HStack>
                        </a>

                        <a href='https://www.facebook.com/profile.php?id=61551685896278' target='_blank' rel='noreferrer'>
                            <HStack>
                                <Icon as={FaFacebookF} color='#c4c0c0' />
                                <Text color='#c4c0c0' cursor='pointer'>
                                    Facebook
                                </Text>
                            </HStack>
                        </a>
                    </VStack>

                </SimpleGrid>

                <Divider />

                <HStack spacing='4' alignItems='start' justifyContent='space-between' p='4'>
                    <HStack spacing={2}>
                        <Box>
                            <Image src='/card-1.png' alt='Logo' />
                        </Box>

                        <Box>
                            <Image src='/card-2.png' alt='VISA Logo' />
                        </Box>

                        <Box>
                            <Image src='/upi.png' alt='UPI Logo' />
                        </Box>
                    </HStack>

                    <VStack alignItems={'start'}>
                        <Text color='#c4c0c0' cursor='pointer'>
                            Vocalthread © 2024 All Right Reserved
                        </Text>
                    </VStack>
                </HStack>

            </Container>
        </Box >
    );
}
