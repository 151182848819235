import { Button, Flex, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useAuth } from '../../../providers/AuthProvider';
// import { useUser } from '../../../providers/UserProvider';
import { useState } from 'react';

const MyProfile = () => {
  const [error, setError] = useState();
  // const [setinfo] = useState(null);
  const { user, updateUserProfile } = useAuth();
  // const { updateUser } = useUser();
  const formik = useFormik({

    initialValues: {
      name: user.name,
      email: user.email,
      phone: user.phone,
    },
    onSubmit: async (values) => {
      var res = updateUserProfile(user.id, values.name, values.phone);
      if (res === "err") {
        setError("Error Adding Contact !");
      }
    }
  });

  return (
    <Flex minH='60vh' align="center" justify="center">
      <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
        <form onSubmit={formik.handleSubmit}>
          {error && <Text>{error}</Text>}
          <VStack spacing={4} align="flex-start">
            <Text fontSize='2xl' as='h3'>My Profile</Text>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                name="name"
                placeholder='Enter Name'
                rounded='0'
                size="lg"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                rounded='0'
                size="lg"
                value={formik.values.email}
                isDisabled
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Phone</FormLabel>
              <Input
                id="phone"
                name="phone"
                type="number"
                placeholder='Enter your phone number'
                rounded='0'
                size="lg"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </FormControl>
            <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
              Update Profile
            </Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default MyProfile;
