import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { url } from "../config";
import { toast } from "react-toastify";

const ContactsContext = createContext();

const ContactsProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const { token, logged } = useAuth();


    async function addContact(name, email, phone, message) {
        console.log(name, email, phone, message);
        setLoading(true);
        try {
            await axios.post(
                `${url}/contacts`,
                { name, email, phone, message },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // toast.success("Contact Added Successfully !");
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                console.log(error.response.data.errors);
                toast.error(error.response.data.errors);
            }
            return "err";
        }
    }

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            addContact
        }),
        [logged, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Contactscontext context to the children components
    return (
        <ContactsContext.Provider value={contextValue}>{children}</ContactsContext.Provider>
    );
};

export const useContacts = () => {
    return useContext(ContactsContext);
};

export default ContactsProvider;