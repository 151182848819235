import { Button, Flex, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const {forgotPassword} = useAuth();

  const navigate = useNavigate();

  const [error] = useState();

  const [info] = useState(null);

  const formik = useFormik({

    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const res = await forgotPassword(values.email);
      if(res === "success"){
        toast.success("Otp Sent Successfully !");
        navigate("/otp-page", { state: { email: values.email } });
      }
    }

  });

  return (
    <Flex minH='60vh' align="center" justify="center">
      <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4} align="flex-start">
            <Text fontSize='2xl' as='h3'>Forgot Password</Text>

            {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
            {info && <Text color='blue'>{info}</Text>}
            <FormControl>
              <FormLabel htmlFor="email">Your Email</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                required={true}
                rounded='0'
                size="lg"
                placeholder='Your Email'
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FormControl>
            <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
              Next
            </Button>

          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default ForgotPassword;

