import { Button, Flex, Box, HStack, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Navigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const { logged, login } = useAuth();
  const [error, setError] = useState();

  const [info, setinfo] = useState(null);

  const formik = useFormik({ 
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      setinfo("Logging in...");
      var res = await login(values.email, values.password);
      setinfo(null);
      console.log(res);
      if (res === "err") {
        setError("Invalid Credentials !");
      } else {
        toast.success("Login Successfully !");
        navigate('/');
      }
    }

  });

  if (logged) {
    return <Navigate to='/' />
  }

  return (
    <Flex minH='60vh' align="center" justify="center">
      <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4} align="flex-start">
            <Text fontSize='2xl' as='h3'>Login to Continue</Text>

            {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
            {info && <Text color='blue'>{info}</Text>}
            <FormControl>
              <FormLabel htmlFor="email">Your Email</FormLabel>
              <Input
                id="email"
                name="email"
                type="text"
                required={true}
                rounded='0'
                size="lg"
                placeholder='Your Email'
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Your Password</FormLabel>
              <Input
                id="password"
                name="password"
                type="password"
                required={true}
                rounded='0'
                size="lg"
                placeholder='Your Password'
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </FormControl>
            <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
              Login
            </Button>
            <HStack justifyContent='end' w='100%'>
              <Link to='/forgot-password'>
                <Text>Forgot Password</Text>
              </Link>
            </HStack>
            <HStack justifyContent='center' w='100%'>
              <Link to='/signup'>
                <HStack spacing='0.5'>
                  <Text>New Here ?</Text>
                  <Text fontWeight='600'>Sign Up</Text>
                </HStack>
              </Link>
            </HStack>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default Login;
