import React, { useEffect, useState } from 'react'
import { SimpleGrid, Text, Container, VStack } from "@chakra-ui/react";
import ProductCard from '../common/ProductCard';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../config';

export default function Search() {

    const { state } = useLocation();
    const [loaded, setloaded] = useState(false);
    const [products, setProduct] = useState([]);

    async function getAllProduct() {
        try {
            var { data } = await axios.get(`${url}/products/search?query=${state.query}`);
            console.log(data.data);
            setProduct(data.data);
            setloaded(true);
            // setLoading(false);
        } catch (error) {
            // console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getAllProduct();
    }, [state.query]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Container maxW='8xl'>
                <VStack w='100%' spacing='4' py={4}>

                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Search Results ♥</Text>

                    {loaded && <Text>Can't find what you are looking for</Text>}

                    <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                        {products.map((p, index) => {
                            return <ProductCard key={index} product={p} />
                        })}
                    </SimpleGrid>
                </VStack>
            </Container >
        </>
    )
}

