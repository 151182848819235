import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { url } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CartsContext = createContext();

const CartProvider = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { token, logged, logout } = useAuth();
    const [carts, setCarts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    async function addCarts(product_id, product_variant_id, quantity) {
        // console.log(product_id, product_variant_id, quantity);
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(
                `${url}/carts`,
                { product_id, product_variant_id, quantity },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(res.data);
            await getAll();
            // toast.success("Added in Cart !");
            toast.success(res.data.success);
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                // console.log(error.response.data.errors);
                toast.error(error.response.data.errors);
            }
            if (error.response.status === 401) {
                // toast.error("Login to continue...");
                logout();
                navigate('/login');
            }
            return "err";
        }
    }

    async function getAll() {
        console.log("GetAll");
        setLoading(true);
        setTotalPrice(0);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.get(
                `${url}/carts`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log(res.data);
            // calculating total
            // var totalPrice1 = 0; 
            let totalPrice2 = 0;
            for (var v of res.data) {
                totalPrice2 += v.quantity * v.product.product_variants.selling_price;
            }
            setTotalPrice(totalPrice2);
            // for (var v of res.data) {
            //     setTotalPrice(totalPrice + v.quantity * v.product.product_variants.selling_price);
            // }
            setCarts(res.data);
            // console.log(carts)
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.errors);
            }
            if (error.response.status === 401) {
                toast.error("Logged out. Please log in again");
                logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        setTotalPrice(0);
        getAll();
    }, [logged]); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            carts,
            addCarts,
            totalPrice,
        }),
        [carts, logged, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Cartscontext context to the children components
    return (
        <CartsContext.Provider value={contextValue}>{children}</CartsContext.Provider>
    );
};

export const useCarts = () => {
    return useContext(CartsContext);
};

export default CartProvider;