import React from 'react';
import { Container, Card, Text, VStack } from "@chakra-ui/react"

export default function PaymentPolicy() {
  return (
    <Container maxW='8xl' my='6'>
      <VStack w='100%' spacing='4'>
        <Card p='5' w='100%'>
          <Text fontSize='2xl' fontWeight='800' className='customfont'>Payment Policy</Text>
        </Card>
        <Card p='5' w='100%'>
          <Text fontSize='lg'>
            Payment Options:
          </Text>
          <Text>
            Online Payments: We accept various secure online payment methods for your convenience.
            Cash on Delivery (COD): Available for selected orders and locations. Please note additional charges may apply.
          </Text>
          <Text fontSize='lg'>
            Refunds:
          </Text>

          <Text> Refunds will be processed only for online payments. </Text>

          <Text> Please refer to our Refund Policy for detailed guidelines. </Text>

        </Card>
      </VStack>
    </Container>
  )
}
