import React from 'react'
import { SimpleGrid, Text, Container, VStack, AspectRatio } from "@chakra-ui/react"
// import { useCategories } from '../../../providers/CategoriesProvider';
import { Link } from 'react-router-dom';

export default function AllCategories() {

    // const { categories } = useCategories();

    let categories = [
        {
            "id": 10,
            "name": "Camera",
            "slug": "camera",
            "image": "https://www.vocalthread.com/cat_banner066bd6de-73ac-4a25-81be-a0de8a372d66.png",
            "banner": "https://www.vocalthread.com/cat_bannerf15f5c53-5d60-4b1b-980f-a35df8acfc04.png",
            "created_at": "2024-06-10T02:08:57.000Z",
            "updated_at": "2024-06-10T02:08:57.000Z"
        },
        {
            "id": 9,
            "name": "Bikers",
            "slug": "bikers",
            "image": "https://www.vocalthread.com/cat_banner83782fb9-045f-4b62-bef3-3420f8139757.png",
            "banner": "https://www.vocalthread.com/cat_banner6065ef7b-d3ce-40e1-a616-a27e32d7c354.png",
            "created_at": "2024-06-10T02:08:26.000Z",
            "updated_at": "2024-06-10T02:08:26.000Z"
        },
        {
            "id": 3,
            "name": "Sarcasm",
            "slug": "sarcasm",
            "image": "https://www.vocalthread.com/cat_bannerd7c59d96-309f-46a0-9a9b-42b998a70b30.png",
            "banner": "https://www.vocalthread.com/cat_banner46c0d13e-e11d-4aa9-a8b9-f908643d554e.png",
            "created_at": "2024-06-10T02:06:20.000Z",
            "updated_at": "2024-06-10T02:06:20.000Z"
        },
        {
            "id": 1,
            "name": "Nerds",
            "slug": "nerds",
            "image": "https://www.vocalthread.com/cat_banner20f80c2d-c646-4a59-a05d-20ace8f07933.png",
            "banner": "https://www.vocalthread.com/cat_banner57d08140-0ff4-4b20-9cde-9bfe7c4b5c0d.png",
            "created_at": "2024-06-10T02:04:32.000Z",
            "updated_at": "2024-06-10T02:04:32.000Z"
        },
        {
            "id": 2,
            "name": "Writers",
            "slug": "writers",
            "image": "https://www.vocalthread.com/cat_banner2b6b8106-cb4f-47cd-b788-68cfc8bf79e6.png",
            "banner": "https://www.vocalthread.com/cat_bannerb45654f4-c3cd-4a92-8c54-2cc9d1fc394b.png",
            "created_at": "2024-06-10T02:05:41.000Z",
            "updated_at": "2024-06-10T02:05:41.000Z"
        },
        {
            "id": 8,
            "name": "Motivational",
            "slug": "motivational",
            "image": "https://www.vocalthread.com/cat_banner1d6bbc02-d44b-4128-a94b-58c95e98736b.png",
            "banner": "https://www.vocalthread.com/cat_bannered851342-f72e-4bb1-8c74-3609de2c9972.png",
            "created_at": "2024-06-10T02:07:34.000Z",
            "updated_at": "2024-06-10T02:07:34.000Z"
        }
    ]

    return (
        <>
            <Container maxW='8xl'>
                <VStack spacing='4'>
                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Our Categories</Text>
                    <Text>Our wonderful categories for you to explore.</Text>

                    <SimpleGrid alignItems='center' justifyContent='center' w='100%' columns={[3, 3, 3, 6]} spacing={[4, 4, 6, 8]}>
                        {categories.map((cat, index) => {
                            return (
                                <Link key={index} to={`/categories/${cat.slug}`}>
                                    <VStack w='100%' spacing='2'>
                                        <AspectRatio ratio={1.04} w='100%'>
                                            <img src={cat.image} alt='category' />
                                        </AspectRatio>
                                        <Text as='h2' className='customfont' fontSize={[14, 18, 18, 20, 22]}>
                                            {cat.name}
                                        </Text>
                                    </VStack>
                                </Link>
                            )
                        })}
                    </SimpleGrid>
                </VStack>
            </Container>
        </>
    )
}
