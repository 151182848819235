import React from 'react';
import { Box, AspectRatio, useBreakpointValue } from '@chakra-ui/react';
import Slider from 'react-slick';
import { useBanners } from '../providers/BannersProvider';
// Settings for the slider


export default function Carousel() {
    // As we have used custom buttons, we need a reference variable to
    // change the state 
    const { banners } = useBanners();
    const arrowShow = useBreakpointValue({ base: false, lg: true })

    const settings = {
        dots: true,
        // arrows: true,
        arrows: arrowShow,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    // const top = useBreakpointValue({ base: '90%', md: '50%' });
    // const side = useBreakpointValue({ base: '30%', md: '10px' });

    // These are the images used in the slide

    return (
        <Box
            width={'full'}
        >
            <Slider {...settings}>
                {banners.map((url, index) => (
                    <Box id={url.id} p='0' bg='red'>
                        <a key={index} href={`https://${url.target}`} target="_blank" rel="noreferrer">
                            <AspectRatio ratio={16 / 4} display={{ base: 'none', md: 'flex' }}>
                                <Box
                                    display={{ base: 'none', md: 'flex' }}
                                    maxHeight={'540px'}
                                    position="relative"
                                    backgroundPosition="center center"
                                    backgroundRepeat="no-repeat"
                                    backgroundSize="cover"
                                    backgroundImage={`url(${url.large_image})`}
                                />
                            </AspectRatio>
                            <AspectRatio ratio={3 / 2} display={{ base: 'flex', md: 'none' }}>
                                <Box
                                    display={{ base: 'flex', md: 'none' }}
                                    // maxHeight={'300px'}
                                    aspectRatio={3 / 2}
                                    position="relative"
                                    bg='white'
                                    w='100%'
                                    backgroundPosition="center center"
                                    backgroundRepeat="no-repeat"
                                    backgroundSize="contain"
                                    backgroundImage={`url(${url.mobile_image})`}
                                />
                            </AspectRatio>
                        </a>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
}