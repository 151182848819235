import React, { useState } from 'react';
import {
  Container,
  VStack,
  Text,
  Button,
  HStack,
  SimpleGrid,
  Card
} from '@chakra-ui/react';
import { useUser } from '../../../providers/UserProvider';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '../../utils/ConfirmDialog';

export default function Address() {
  const { address, deleteAddress } = useUser();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const handleConfirm = async () => {
    try {
      await deleteAddress(selectedId);
      setIsOpen(false);
    } catch (error) {

    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Container
      maxW='8xl'
      my='6'
    >
      <VStack w='100%'>
        <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl'>My Addresses</Text>
        <Link to='/address/add'>
          <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
            Add New Address
          </Button>
        </Link>
        <SimpleGrid
          w='100%'
          columns={[1, 2, 3]}
          spacing='4'
          my={4}
        >
          {address.map((add, idx) => {
            return (<Card p={4} key={idx}>
              <VStack alignItems={'start'} spacing={2}>
                <Text fontWeight={400} fontSize={'sm'}>{add.address}</Text>
                <Text fontSize={'sm'} color={'grey'}>{add.phone}</Text>
                <Text fontSize={'sm'} color={'grey'}>{add.locality}</Text>
                <Text fontSize={'sm'} color={'grey'}>{add.city},<Text pl={1} as={'span'}>{add.state}</Text></Text>
                <Text fontSize={'sm'} color={'grey'}>{add.pincode}</Text>
                <HStack>
                  <Link to='/address/update' state={add}>
                    <Button
                      type="submit"
                      bg="#1c1c1c"
                      _hover={{ bg: '#1d1d1d' }}
                      color="#fefefe"
                      py='4'
                      borderRadius='0'
                    >
                      Update
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    bg="#1c1c1c"
                    _hover={{ bg: '#1d1d1d' }}
                    color="#fefefe"
                    py='4'
                    borderRadius='0'
                    onClick={() => { setSelectedId(add.id); setIsOpen(true); }}
                  >
                    Delete
                  </Button>
                </HStack>
              </VStack>
            </Card>)
          })}

        </SimpleGrid>
      </VStack>

      <ConfirmDialog
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Are you sure?"
        body="This action cannot be undone."
      />
    </Container>
  )
}
