import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";

const CategoriesContext = createContext();

const CategoriesProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const [categories, setCategories] = useState([]);

    async function getAll() {
        try {
            var { data } = await axios.get(`${url}/categories`);
            console.log(data.data);
            setCategories(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            categories,
        }),
        [categories, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Categoriescontext context to the children components
    return (
        <CategoriesContext.Provider value={contextValue}>{children}</CategoriesContext.Provider>
    );
};

export const useCategories = () => {
    return useContext(CategoriesContext);
};

export default CategoriesProvider;