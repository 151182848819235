import { Button, Flex, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useUser } from '../../../providers/UserProvider';
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

const AddAddress = () => {
    // const navigate = useNavigate();
    const { addAddress } = useUser();
    const [error, setError] = useState();

    const [info, setinfo] = useState(null);

    const formik = useFormik({
        initialValues: {
            address: "",
            phone: "",
            locality: "",
            pincode: "",
            city: "",
            state: "",
        },
        onSubmit: async (values) => {
            setError("");
            setinfo("Adding...");
            var res = await addAddress(values.address, values.phone, values.locality, values.city, values.state, values.pincode);
            setinfo(null);
            if (res === "err") {
                setError("Error Adding Contact !");
            } else {
                values.address = "";
                values.phone = "";
                values.locality = "";
                values.city = "";
                values.state = "";
                values.pincode = "";
            }
        }

    });

    return (
        <Flex minH='60vh' align="center" justify="center">
            <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='2xl' as='h3'>Add a new Address</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <Input
                                id="address"
                                name="address"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Address'
                                onChange={formik.handleChange}
                                value={formik.values.address}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            <Input
                                id="phone"
                                name="phone"
                                type="number"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Phone No'
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="locality">Locality</FormLabel>
                            <Input
                                id="locality"
                                name="locality"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Locality'
                                onChange={formik.handleChange}
                                value={formik.values.locality}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="pincode">Pincode</FormLabel>
                            <Input
                                id="pincode"
                                name="pincode"
                                type="number"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Pincode'
                                onChange={formik.handleChange}
                                value={formik.values.pincode}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">City</FormLabel>
                            <Input
                                id="city"
                                name="city"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your City'
                                onChange={formik.handleChange}
                                value={formik.values.city}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="state">State</FormLabel>
                            <Input
                                id="state"
                                name="state"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your State'
                                onChange={formik.handleChange}
                                value={formik.values.state}
                            />
                        </FormControl>
                        <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
                            Add Address
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default AddAddress;
