import {
    Button,
    Menu,
    MenuList,
    HStack,
    Text,
    MenuButton,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import React from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { useCategories } from '../../providers/CategoriesProvider';
import { useAuth } from '../../providers/AuthProvider';

export default function NavLinks() {
    const { isOpen: isProductsOpen, onOpen: onProductsOpen, onClose: onProductsClose } = useDisclosure();
    // const { isAccountOpen: isAccountOpen, onAccountOpen: onAccountOpen, onAccountClose: onAccountClose } = useDisclosure();
    const { isOpen: isAccountOpen, onOpen: onAccountOpen, onClose: onAccountClose } = useDisclosure();
    const { logged, logout } = useAuth();

    const { categories } = useCategories();
    return (
        <>
            <Link to={'/'}>
                <Button borderRadius='md' colorScheme='black'>
                    Home
                </Button>
            </Link>

            <Link to={'/shop'}>
                <Button borderRadius='md' colorScheme='black'>
                    Shop
                </Button>
            </Link>

            <Menu isOpen={isProductsOpen} matchWidth={true}>
                {({ isOpen2 }) => (
                    <>
                        <MenuButton
                            isActive={isProductsOpen}
                            as={Button}
                            borderRadius='lg'
                            onMouseEnter={onProductsOpen}
                            onMouseLeave={onProductsClose}
                            bg="#1d1d1d"
                            _active={{ bg: "#1d1d1d", border: 'none' }}
                            _hover={{ bg: "#1d1d1d", border: 'none' }}
                            color='#fefefe'
                            rightIcon={<AiOutlineCaretDown />}
                        >
                            <Text>Categories</Text>
                        </MenuButton>

                        <MenuList w='200px' border='0' mt='-10px' onMouseEnter={onProductsOpen} onMouseLeave={onProductsClose} p='0' bg='#1c1c1c' rounded='0'>

                            <HStack p='6' alignItems='start' justifyContent={'space-between'} h='100%' spacing='8'>

                                <VStack w='100%' alignItems='start' justifyContent='flex-start' h='100%' spacing='6'>

                                    {categories.map((cat, index) => {
                                        return (
                                            <a key={index} href={`/categories/${cat.slug}`}>
                                                <Text color="#fefefe" className='custom'>{cat.name}</Text>
                                            </a>
                                        )
                                    })}

                                </VStack>

                            </HStack>
                        </MenuList>
                    </>
                )}
            </Menu>

            {logged ? <>
                <Menu isOpen={isAccountOpen} matchWidth={true}>
                    {({ isOpen }) => (
                        <>
                            <MenuButton
                                isActive={isOpen}
                                as={Button}
                                borderRadius="lg"
                                onClick={isOpen ? onAccountClose : onAccountOpen}
                                onMouseEnter={onAccountOpen}
                                onMouseLeave={onAccountClose}
                                bg="#1d1d1d"
                                _active={{ bg: "#1d1d1d" }}
                                _hover={{ bg: "#1d1d1d" }}
                                color="#fefefe"
                                rightIcon={<AiOutlineCaretDown />}
                            >
                                <Text>Account</Text>
                            </MenuButton>

                            <MenuList
                                w="200px"
                                border="0"
                                mt="-10px"
                                onMouseEnter={onAccountOpen}
                                onMouseLeave={onAccountClose}
                                p="0"
                                bg="#1c1c1c"
                                rounded="0"
                            >
                                <HStack p="6" alignItems="start" justifyContent={'space-between'} h="100%" spacing="8">
                                    <VStack w="100%" alignItems="start" justifyContent="flex-start" h="100%" spacing="6">
                                        <Link to={`/orders`}>
                                            <Text color="#fefefe" className="custom">Orders</Text>
                                        </Link>
                                        <Link to={`/address`}>
                                            <Text color="#fefefe" className="custom">Address</Text>
                                        </Link>
                                        <Link to={`/my-profile`}>
                                            <Text color="#fefefe" className="custom">My Profile</Text>
                                        </Link>
                                    </VStack>
                                </HStack>
                            </MenuList>
                        </>
                    )}
                </Menu>

                <Button onClick={logout} borderRadius='md' colorScheme='black'>
                    Logout
                </Button>

            </> : <>
                <Link to={'/login'}>
                    <Button borderRadius='md' colorScheme='black'>
                        Login
                    </Button>
                </Link>

                <Link to={'/signup'}>
                    <Button borderRadius='md' colorScheme='black'>
                        Sign Up
                    </Button>
                </Link>
            </>}

        </>
    )
}
