import React from 'react'
import { SimpleGrid, Text, Container, VStack } from "@chakra-ui/react";
import { useProduct } from '../../providers/ProductProvider';
import ProductCard from '../common/ProductCard';
import ReactGA from 'react-ga4';

export default function Shop() {

    const { products } = useProduct();

    ReactGA.send({
        hitType: 'pageview',
        page: "/shop",
        title: "Shop",
    });

    return (
        <>
            <Container maxW='8xl'>
                <VStack w='100%' spacing='4' py={4}>

                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Our Collection to Explore ♥</Text>

                    <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                        {products.map((p, index) => {
                            return <ProductCard key={index} product={p} />
                        })}
                    </SimpleGrid>
                </VStack>
            </Container >
        </>
    )
}