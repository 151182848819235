import React from 'react'
import { HStack, VStack, AspectRatio, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom'
// import { FaRupeeSign } from "react-icons/fa";


export default function ProductCard({ product }) {
    return (
        <Link to={`/product/${product.product_variants.slug}`}>
            <VStack spacing='1'>
                <AspectRatio ratio={1} w='100%'>
                    <img src={product.product_variants.image1} alt='product' />
                </AspectRatio>
                <Text w='100%' className='twoLineText' as='h1' fontSize='md'>
                    {product.products.name}
                </Text>
                <HStack justifyContent='start' w='100%'>
                    <HStack spacing='0'>
                        <Image src='/rupee_light.png' w='6px' me='0.5' />
                        <Text style={{ textDecoration: 'line-through' }} fontSize='sm'>
                            {product.product_variants.mrp}
                        </Text>
                    </HStack>

                    <HStack spacing='0'>
                        <Image src='/rupee.png' w='10px' me='0.5' />
                        <Text as='h1' className='customfont' fontSize='md'>
                            {product.product_variants.selling_price}
                        </Text>
                    </HStack>

                    <Text
                        color='#ff5c5c'
                        fontSize={[12, 14, 18, 18]}>
                        ({Math.round(((product.product_variants.mrp - product.product_variants.selling_price) / product.product_variants.mrp) * 100)}% off)
                    </Text>
                </HStack>
            </VStack>
        </Link>
    )
}
