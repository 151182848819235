import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    // State to hold the authentication token
    const [token, setToken_] = useState();
    const [logged, setLogged] = useState(false);

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
        if (newToken == null) {
            localStorage.removeItem("vocalthread_user_token");
        } else {
            localStorage.setItem("vocalthread_user_token", newToken);
        }
    };

    async function login(email, password) {
        console.log("LOGIN");
        console.log(email);
        console.log(password);
        setLoading(true);

        try {
            var { data } = await axios.post(`${url}/login`, {
                "email": email,
                "password": password,
            });
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            console.log(data);
            setLogged(true);
            setToken(data.token);
            setLoading(false);
            // console.log(data.token);
            setUser(data.user);
            localStorage.setItem("user", JSON.stringify(data.user));
            console.log(user);
            // console.log(data.user);
        } catch (error) {
            return "err";
        }
    }

    async function signUp(name, email, password) {
        console.log("SignUp");
        setLoading(true);
        try {
            var { data } = await axios.post(`${url}/register`,
                {
                    "name": name,
                    "email": email,
                    "password": password,
                }
            );
            console.log(data);
            setLoading(false);
            console.log(user);
            toast.success("Account successfully registered");
            return "success";
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                // return error.response.data.error;
                toast.error(error.response.data.error);
            }
            return "error";
        }
    }

    async function forgotPassword(email) {
        console.log("Forgot Password");
        setLoading(true);
        try {
            var { data } = await axios.post(`${url}/forgot-password-otp`,
                {
                    "email": email,
                }
            );
            console.log(data);
            setLoading(false);
            console.log(user);
            return "success";
        } catch (error) {
            console.error(error);
            if (error.response.status === 404) {
                toast.error("User not found");
            }
            return "error";
        }
    }

    async function verifyForgotOtp(email, otp) {
        console.log("Verify Forgot Otp");
        console.log(email, otp)
        setLoading(true);
        try {
            var { data } = await axios.post(`${url}/verify-forgot-otp`,
                {
                    "email": email,
                    "otp": otp,
                }
            );
            console.log(data);
            setLoading(false);
            console.log(user);
            return "success";
        } catch (error) {
            console.error(error);
            if (error.response.status === 404) {
                toast.error("User not found with this email !");
            }
            if (error.response.status === 400) {
                toast.error("Invalid Otp !");
            }
            return "error";
        }
    }

    async function resetPassword(email,new_password, otp) {
        console.log("Reset Password");
        console.log(email,new_password, otp)
        setLoading(true);
        try {
            var { data } = await axios.post(`${url}/reset-password`,
                {
                    "email": email,
                    "new_password":new_password,
                    "otp": otp,
                }
            );
            console.log(data);
            setLoading(false);
            console.log(user);
            return "success";
        } catch (error) {
            console.error(error);
            if (error.response.status === 404) {
                toast.error("User not found");
            }
            if (error.response.status === 400) {
                toast.error("Otp Expired !");
            }
            return "error";
        }
    }

    async function updateUserProfile(id, name, phone) {
        setLoading(true);
        console.log(token)
        try {
            console.log(id, name, phone,)
            const { data } = await axios.put(
                `${url}/user/update`,
                {id, name, phone},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(data.user)
            setUser(data.user);
            localStorage.setItem("user", JSON.stringify(data.user));
            toast.success("Profile Updated Successfully !");
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                console.log(error.response.data.errors);
                toast.error(error.response.data.errors[0]);
            }
            return "err";
        }
    }

    // Call the logout endpoint and then remove the user
    // from the state.
    function logout() {
        setLogged(false);
        setLoading(false);
        setToken(null);
        // sessionsApi.logout().then(() => setUser(undefined));
    }

    useEffect(() => {

        let tkn = localStorage.getItem("vocalthread_user_token");
        console.log(tkn);
        console.log(typeof tkn);

        if (tkn !== null && tkn !== "null") {
            setLogged(true);
            setLoading(false);
            setToken(tkn);

            // set user too
            setUser(JSON.parse(localStorage.getItem("user")));
        } else {
            setLogged(false);
            setLoading(false);
            setToken(null);
        }

        if (tkn) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            // localStorage.setItem('token', token);
        } else {
            // delete axios.defaults.headers.common["Authorization"];
            // localStorage.removeItem('token')
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            logged,
            token,
            user,
            login,
            signUp,
            forgotPassword,
            verifyForgotOtp,
            resetPassword,
            setToken,
            updateUserProfile,
            logout,
        }),
        [token, logged] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;