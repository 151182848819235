import React from 'react';
import {
    Container,
    Divider,
    VStack,
    Text,
    SimpleGrid
} from '@chakra-ui/react';

export default function Features() {
    return (
        <Container
            maxW='8xl'
        >
            <Divider />
            <SimpleGrid
                columns={[1, 2, 3, 4]}
                spacing='8'
                my={4}
            >
                <VStack alignItems={'start'}>
                    <Text fontWeight={800} fontSize={'xl'}>Free Shipping</Text>
                    <Text fontSize={'sm'} color={'grey'}>Free Shipping on All Orders</Text>
                </VStack>

                <VStack alignItems={'start'}>
                    <Text fontWeight={800} fontSize={'xl'}>Secure Payment</Text>
                    <Text fontSize={'sm'} color={'grey'}>We ensure secure UPI & Card Payment.</Text>
                </VStack>

                <VStack alignItems={'start'}>
                    <Text fontWeight={800} fontSize={'xl'}>Support 24/7</Text>
                    <Text fontSize={'sm'} color={'grey'}>Contact us 24 hours a day, 7 days a week</Text>
                </VStack>

                <VStack alignItems={'start'}>
                    <Text fontWeight={800} fontSize={'xl'}>10 Days Return</Text>
                    <Text fontSize={'sm'} color={'grey'}>Simply return it within 10 days for an exchange</Text>
                </VStack>

            </SimpleGrid>
            <Divider />
        </Container>
    )
}
