
import { Button, Flex, HStack, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const SignUp = () => {
    const { signUp, logged } = useAuth();
    const navigate = useNavigate();
    const [error] = useState();

    const [info, setinfo] = useState(null);

    const formik = useFormik({

        initialValues: {
            name: "",
            email: "",
            password: "",
        },
        onSubmit: async (values) => {
            // alert(JSON.stringify(values, null, 2));

            try {

                setinfo("Creating Your account...");
                var res = await signUp(values.name, values.email, values.password);
                setinfo(null);
                console.log(res);
                navigate('/login');
            } catch (error) {

            }
            // if (res === "err") {
            //     setError("Invalid Credentials !");
            // } else if (res === "success") {
            //     navigate('/login');
            // }
        }
    });

    if (logged) {
        return <Navigate to='/' />
    }

    return (
        <Flex minH='60vh' align="center" justify="center" >
            <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my='6'>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='2xl' as='h3'>Create an Account</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="name">Your Name</FormLabel>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Your Email</FormLabel>
                            <Input
                                id="email"
                                name="email"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Your Password</FormLabel>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Password'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </FormControl>
                        <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
                            Sign Up
                        </Button>
                        <HStack justifyContent='center' w='100%'>
                            <Link to='/login'>
                                <HStack spacing='0.5'>
                                    <Text>Already Registered ?</Text>
                                    <Text fontWeight='600'>Login</Text>
                                </HStack>
                            </Link>
                        </HStack>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default SignUp;
