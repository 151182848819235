import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";
import { toast } from "react-toastify";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const { token, logged } = useAuth();

    const [address, setAddress] = useState([]);

    async function getAllAddress() {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${url}/address`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setAddress(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            return "err";
        }
    }

    async function addAddress(address, phone, locality, city, state, pincode) {
        setLoading(true);
        try {
            console.log(address, phone, locality, city, state, pincode)
            const { data } = await axios.post(
                `${url}/address`,
                { address, phone, locality, city, state, pincode },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            getAllAddress();
            setAddress(data.data);
            toast.success("Address Added Successfully !");
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                console.log(error.response.data.errors);
                toast.error(error.response.data.errors[0]);
            }
            return "err";
        }
    }

    async function updateAddress(id, address, phone, locality, city, state, pincode) {
        setLoading(true);
        try {
            console.log(id, address, phone, locality, city, state, pincode)
            const { data } = await axios.put(
                `${url}/address`,
                { id, address, phone, locality, city, state, pincode },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            getAllAddress();
            setAddress(data.data);
            toast.success("Address Updated Successfully !");
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                console.log(error.response.data.errors);
                toast.error(error.response.data.errors[0]);
            }
            return "err";
        }
    }

    async function deleteAddress(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/address`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "id": id,
                },
            }
            );
            await getAllAddress();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            } else {
                toast.error("Error Deleting !");
            }
            return "err";
        }
    }

    useEffect(() => {
        getAllAddress();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            address,
            addAddress,
            deleteAddress,
            updateAddress,
        }),
        [logged, address] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Usercontext context to the children components
    return (
        <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};

export default UserProvider;