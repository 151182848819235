import {
    Button,
    Flex,
    Box,
    HStack,
    VStack,
    Text,
    FormControl,
    PinInput,
    PinInputField,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { toast } from "react-toastify";

const OtpPage = () => {
    const {verifyForgotOtp} = useAuth();

    const navigate = useNavigate();

    const location = useLocation();
    const { email } = location.state || {};

    // const [otp, setOtp] = useState('');

    const [error] = useState();

    const [info] = useState(null);

    const formik = useFormik({

        initialValues: {
            otp: "",
        },
        onSubmit: async (values) => {
            const res = await verifyForgotOtp(email, values.otp);
            if(res === "success"){
              toast.success("Otp Matched Successfully !");
              navigate("/reset-password", { state: { email: email,otp:values.otp } });
            }
        }

    });

    const handleOtpChange = (value) => {
        formik.setFieldValue('otp', value);
    };

    return (
        <Flex minH='60vh' align="center" justify="center">
            <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={2} align="flex-start">
                        <Text fontSize='2xl' as='h3'>Enter Verification Code</Text>
                        <Text fontSize='md' as='p'>Please, check your email. we’ve sent you the code</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <HStack my={4}>

                            <FormControl>
                                <PinInput onChange={handleOtpChange}>
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />
                                    <PinInputField
                                        bg={'#FFFFFF'}
                                        rounded={0}
                                        h={12}
                                        w={12}
                                        fontSize={20}
                                        mr={2}
                                    />

                                </PinInput>
                            </FormControl>
                        </HStack>

                        <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
                            Submit
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default OtpPage;


