import { Button, Flex, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { toast } from "react-toastify";

const ResetPassword = () => {
    const { resetPassword } = useAuth();

    const { state } = useLocation();

    const navigate = useNavigate();

    const [error] = useState();

    const [info] = useState(null);

    const formik = useFormik({

        initialValues: {
            new_password: "",
        },
        onSubmit: async (values) => {
            console.log(values);
            const res = await resetPassword(state.email, values.new_password, state.otp);
            if (res === "success") {
                toast.success("Password changed successfully");
                navigate("/");
            }
        }

    });

    useEffect(() => {
        console.log(state);
    })
    return (
        <Flex minH='60vh' align="center" justify="center">
            <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='2xl' as='h3'>Reset Password</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="new_password">Enter new password</FormLabel>
                            <Input
                                id="new_password"
                                name="new_password"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Enter new password'
                                onChange={formik.handleChange}
                                value={formik.values.new_password}
                            />
                        </FormControl>
                        <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
                            Change Password
                        </Button>

                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default ResetPassword;

