import {
    Container,
    VStack,
    HStack,
    Button,
    Flex,
    Image,
    Text,
    Badge,
    Box,
    Spacer
} from '@chakra-ui/react';
import React from 'react'

export default function MyOrders() {

    const orders = [
        {
            id: '12345',
            productName: 'Enlighten Yourself',
            imageUrl: 'https://codecart-cdn.b-cdn.net/vocalthread/products/product34fd09e3-d8dd-445c-bfc8-6c41facc0c26.webp',
            price: 2499,
            status: 'Delivered',
            orderDate: '2024-06-20',
        },
        {
            id: '67890',
            productName: 'Raise Your gear When in fear',
            imageUrl: 'https://codecart-cdn.b-cdn.net/vocalthread/products/productf896af11-8342-484e-b247-0a9125036be2.webp',
            price: 1399,
            status: 'In Transit',
            orderDate: '2024-06-25',
        },
    ];

    return (
        <Container maxW='6xl' my='8'>
            <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl' mb='2'>My Orders</Text>
            <VStack spacing={4}>
                {orders.map((order) => (
                    <Box key={order.id} borderWidth="1px" bg={'#fcfcfc'} borderRadius="lg" w="100%" p={5}>
                        <Flex align="start">
                            <Image boxSize="120px" objectFit="cover" src={order.imageUrl} alt={order.productName} />
                            <VStack align="start" ml={5} flex={1}>
                                <Text fontSize="lg" fontWeight="bold">{order.productName} + 2 other items</Text>
                                <HStack>
                                    <Text fontSize="md" color="gray.600">Order ID: {order.id}</Text>
                                </HStack>
                                <Text fontSize="md" fontWeight="bold">Order Total : ₹{order.price}</Text>
                                <Badge colorScheme={order.status === 'Delivered' ? 'green' : 'orange'}>{order.status}</Badge>
                                <Text fontSize="sm" color="gray.500">Order Date: {order.orderDate}</Text>
                                <Spacer />
                                <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
                                    View Details
                                </Button>
                            </VStack>
                        </Flex>
                    </Box>
                ))}
            </VStack>
        </Container>
    )
}





