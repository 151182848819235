import React from 'react'
import { VStack } from "@chakra-ui/react"
import Features from '../../Features'
import AllCategories from '../categories/AllCategories'
import Carousel from '../../Carousel'
import NewProducts from '../../Home/NewProducts'
import PopularProducts from '../../Home/PopularProducts'

export default function Home() {
    return (
        <VStack spacing='8'>
            <Carousel />
            <AllCategories />
            <NewProducts />
            <PopularProducts />
            <Features />
        </VStack>
    )
}
