import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const [products, setProduct] = useState([]);
    const [popularProduct, setPopularProduct] = useState([]);
    const [latestProduct, setLatestProduct] = useState([]);

    async function getAllProduct() {
        try {
            var { data } = await axios.get(`${url}/products/productvariant`);
            console.log(data.data);
            setProduct(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function getAllPopularProduct() {
        try {
            var { data } = await axios.get(`${url}/products/popular`);
            console.log(data.data);
            setPopularProduct(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function getAllLatestProduct() {
        try {
            var { data } = await axios.get(`${url}/products/latest`);
            console.log(data.data);
            setLatestProduct(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getAllProduct();
        getAllPopularProduct();
        getAllLatestProduct();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            products,
            popularProduct,
            latestProduct,
        }),
        [products, popularProduct, latestProduct, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Productcontext context to the children components
    return (
        <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>
    );
};

export const useProduct = () => {
    return useContext(ProductContext);
};

export default ProductProvider;