import React from 'react'
import { SimpleGrid, Text, Container, VStack } from "@chakra-ui/react" 
import { useProduct } from '../../providers/ProductProvider'; 
import ProductCard from '../common/ProductCard';

export default function NewProducts() {

    const { latestProduct } = useProduct();

    return (
        <>
            <Container maxW='8xl'>
                <VStack w='100%' spacing='4'>

                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Latest to our Collection</Text>
                    <Text>Explore the latest addition to our Collection.</Text>

                    <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                        {latestProduct.map((l, index) => {
                            return <ProductCard key={index} product={l} />
                        })}
                    </SimpleGrid>
                </VStack>
            </Container>
        </>
    )
}
