import { Button, Flex, Box, Textarea, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useContacts } from '../../providers/ContactProvider';
import { toast } from 'react-toastify';

const Contact = () => {
    const { addContact } = useContacts();

    const [error, setError] = useState();

    const [info, setinfo] = useState(null);

    const formik = useFormik({

        initialValues: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        onSubmit: async (values) => {
            setinfo("Adding...");
            var res = await addContact(values.name, values.email, values.phone, values.message);
            setinfo(null);
            if (res === "err") {
                setError("Error Adding Contact !");
            } else {
                values.name = "";
                values.email = "";
                values.phone = "";
                values.message = "";
                toast.success("Thanks for your message!. We will get back to you soon");
            }
        }

    });

    return (
        <Flex minH='60vh' align="center" justify="center">
            <Box bg="white" p={6} maxW={'500px'} w='90%' rounded="0" shadow="md" my={6}>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='2xl' as='h2' textAlign='center'>Contact Us</Text>
                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="name">Your Name</FormLabel>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Your Email</FormLabel>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="phone">Your Phone</FormLabel>
                            <Input
                                id="phone"
                                name="phone"
                                type="phone"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Phone'
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="message">Your Query</FormLabel>
                            <Textarea
                                id="message"
                                name="message"
                                type="text"
                                required={true}
                                rounded='0'
                                size="lg"
                                placeholder='Your Query'
                                onChange={formik.handleChange}
                                value={formik.values.message}
                            />
                        </FormControl>
                        <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='7' width="full">
                            Send Message
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default Contact;
