import React, { useEffect } from 'react'
import { SimpleGrid, Text, Container, VStack } from "@chakra-ui/react";
import { useProduct } from '../../providers/ProductProvider'; 
import ProductCard from '../common/ProductCard';

export default function PopularProducts() {

    const { popularProduct } = useProduct();

    useEffect(() => {
        console.log(popularProduct)
    })

    return (
        <>
            <Container maxW='8xl'>
                <VStack w='100%' spacing='4'>

                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Most Loved ♥</Text>
                    <Text>Most Loved Products from our collection.</Text>

                    <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                        {popularProduct.map((p, index) => {
                            return <ProductCard key={index} product={p} />
                        })}
                    </SimpleGrid>
                </VStack>
            </Container >
        </>
    )
}
