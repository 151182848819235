import {
    Container,
    VStack,
    Divider,
    HStack,
    Button,
    Flex,
    Image,
    Text,
    Heading,
    Box,
    IconButton,
    Spacer
} from '@chakra-ui/react';
import { AddIcon, MinusIcon, DeleteIcon } from '@chakra-ui/icons';
import React from 'react'
import { useCarts } from '../../providers/CartProvider';
import { Link } from 'react-router-dom';

export default function Cart() {

    var { carts, totalPrice, addCarts } = useCarts();

    const calculateDiscount = (price, sellPrice) => {
        return Math.round(((price - sellPrice) / price) * 100);
    };

    return (
        <Container maxW='7xl' my='8'>
            <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl' mb='2'> My Cart</Text>

            {carts.length === 0 && <>
                <VStack w='100%'>
                    <Text>Your Cart is Empty</Text>
                    <Text>Please explore our wide range of collections.</Text>
                </VStack>
            </>
            }

            {carts.length !== 0 &&
                <Flex direction={{ base: 'column', lg: 'row' }}>
                    <VStack w={{ base: '100%', lg: '70%' }} spacing={4}>
                        {carts.map((item) => (
                            <Box key={item.id} borderWidth="1px" borderRadius="lg" bg={'#fcfcfc'} w="100%">
                                <Flex p={5} align="center">
                                    <Image boxSize="120px" objectFit="cover" src={item.product.product_variants.image1} alt={item.product.products.name} />
                                    <VStack align="start" ml={5}>
                                        <Text fontSize="lg" fontWeight="bold">{item.product.products.name}</Text>
                                        <Text fontSize='sm' color="gray.500">Size: {item.product.product_variants.size}</Text>
                                        <HStack>
                                            <Text fontSize='sm' color="gray.500" textDecoration="line-through">₹{item.product.product_variants.mrp}</Text>
                                            <Text fontSize="md" fontWeight="bold">₹{item.product.product_variants.selling_price}</Text>
                                            <Text fontSize='xs' color='#ff5c5c'>({calculateDiscount(item.product.product_variants.mrp, item.product.product_variants.selling_price)}% off)</Text>
                                        </HStack>
                                        <HStack>
                                            <IconButton
                                                onClick={() => addCarts(item.product_id, item.product_variant_id, (item.quantity - 1))}
                                                icon={<MinusIcon />}
                                                aria-label="Decrease quantity"
                                                size="sm"
                                            />
                                            <Text>{item.quantity}</Text>
                                            <IconButton
                                                onClick={() => addCarts(item.product_id, item.product_variant_id, (item.quantity + 1))}
                                                icon={<AddIcon />}
                                                aria-label="Increase quantity"
                                                size="sm"
                                            />
                                            <Spacer />
                                            <IconButton
                                                onClick={() => addCarts(item.product_id, item.product_variant_id, 0)}
                                                icon={<DeleteIcon />}
                                                aria-label="Remove item"
                                                size="sm"
                                                colorScheme="red"
                                            />
                                        </HStack>
                                    </VStack>
                                </Flex>
                                <Divider />
                            </Box>
                        ))}
                    </VStack>
                    <Box
                        h={{ base: '100%', lg: '40%' }}
                        w={{ base: '100%', lg: '30%' }}
                        p={5}
                        borderWidth="1px"
                        borderRadius="lg"
                        bg={'#fcfcfc'}
                        ml={{ lg: '18px' }}
                        mt={{ base: '18px', lg: '0' }}
                    >
                        <Heading size="md" mb={5}>Order Summary</Heading>
                        <VStack spacing={4}>
                            <Flex w="100%" justify="space-between">
                                <Text>Subtotal</Text>
                                <Text>₹{totalPrice}</Text>
                            </Flex>
                            <Flex w="100%" justify="space-between">
                                <Text>Shipping</Text>
                                <Text>Free</Text>
                            </Flex>
                            <Divider />
                            <Flex w="100%" justify="space-between" fontWeight="bold">
                                <Text>Total</Text>
                                <Text>₹{totalPrice}</Text>
                            </Flex>
                            <Link to='/checkout'>
                                <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
                                    Proceed to Checkout
                                </Button>
                            </Link>
                        </VStack>
                    </Box>
                </Flex>
            }
        </Container>
    )
}




