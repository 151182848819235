import { Container, VStack, Image, SimpleGrid, Text } from "@chakra-ui/react"
import React, { useState, useEffect } from 'react'
import { useCategories } from "../../../providers/CategoriesProvider"
import { useParams } from "react-router-dom";
import ProductCard from "../../common/ProductCard";
import axios from "axios";
import { url } from "../../../config";

export default function CategoryProducts() {

    const { categories } = useCategories();

    const [loaded, setLoaded] = useState(false);
    const [categoryProducts, setcategoryProducts] = useState([]);

    const params = useParams();

    const [category, setCategory] = useState(null);

    async function getCategoryProducts() {
        try {
            var { data } = await axios.get(`${url}/products/category/${params.slug}`);
            // console.log(data['categoryProducts']);
            setcategoryProducts(data['categoryProducts']);
        } catch (error) {
            console.error(error);
        } finally {
            setLoaded(true);
        }
    }

    useEffect(() => {
        console.log(params)
        if (params.slug !== undefined) {
            getCategoryProducts();
        }
        setCategory(categories.find(c => c.slug === params.slug))
    }, [categories, window.location.pathname]) // eslint-disable-line

    return (
        <Container maxW={{ base: '9xl', md: '8xl' }} p='0'>

            <VStack w='100%' spacing='0'>
                {category != null ?
                    <>
                        <Image display={{ base: 'flex', lg: 'none' }} w='100%' src={category.banner} alt={`${category.name} Banner`} />
                        {category.small_banner != null ?
                            <Image display={{ base: 'none', lg: 'flex' }} w='100%' src={category.small_banner} alt={`${category.name} Small Banner`} />
                            : <></>}
                    </>
                    : <></>
                }

                {category !== null && categoryProducts.length === 0 && loaded ?
                    <>
                        <Text my='4' textAlign='center'>There are no products in this category. Plese visit soon. </Text>
                    </>
                    : <></>
                }

                <Container maxW='8xl' my='6'>
                    <VStack w='100%'>
                        <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                            {categoryProducts.map((p, index) => {
                                return <ProductCard key={index} product={p} />
                            })}
                        </SimpleGrid>
                    </VStack>
                </Container >

            </VStack>
        </Container>
    )
}
