import {
    Box,
    HStack,
    useDisclosure,
    Flex,
    Input,
    Button,
    Image,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import 'react-toastify/dist/ReactToastify.css';
import { useCarts } from '../../providers/CartProvider';

export default function NavBar() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchEnabled, setsearchEnabled] = useState(false);
    const { carts } = useCarts();

    const [searchText, setSearchText] = useState("");

    return (
        <>
            <Box bg='#1c1c1c' position='sticky' top='0' px={4} py='2' zIndex='2'>
                <Flex h={20} alignItems={'center'} justifyContent='space-between'>

                    <HStack w={{ base: '28px', md: '100%' }} spacing={5} alignItems={'center'} >
                        <Image display={{ md: 'none' }} cursor='pointer' me='2' src='/ham.png' alt='Search Icon' w='22px' onClick={isOpen ? onClose : onOpen} />

                        <Link to="/">
                            <Image display={{ base: 'none', md: 'flex' }} mx='2' src="/logo-dark.png" width='140px' alt="Logo Code Card" />
                        </Link>

                        <HStack as={'nav'} w='100%' alignItems={'center'} justifyContent={'center'} spacing={2} display={{ base: 'none', md: 'flex' }}>

                            <NavLinks />

                        </HStack>
                    </HStack>

                    <Link to="/">
                        <Image display={{ base: 'flex', md: 'none' }} mx='2' src="/logo-dark.png" width='140px' alt="Logo Code Card" />
                    </Link>

                    <HStack spacing='5'>
                        <Image
                            src='/search.png'
                            alt='Search Icon'
                            w='22px'
                            cursor='pointer'
                            onClick={() => setsearchEnabled(!searchEnabled)}
                        />

                        <Link to='/cart'>
                            <div className='image-container'>
                                <Image
                                    src='/bag.png'
                                    alt='Search Icon'
                                    w='22px'
                                    me='0.5'
                                    cursor='pointer'
                                />
                                {carts.length > 0 &&
                                    <div className="cart-count">{carts.length}</div>
                                }
                            </div>
                        </Link>

                    </HStack>

                </Flex>

                {isOpen ? (
                    <NavLinks />
                ) : null}

                {searchEnabled &&
                    <HStack alignItems='center' justifyContent='center' style={{ transition: 'all ease-in-out 300ms' }}>
                        <Input
                            onChange={(t) => {
                                setSearchText(t.target.value)
                            }}
                            w='100%'
                            maxW='500px'
                            color='#fefefe'
                            placeholder="Search For a Product"
                        />
                        <Link to='/search' state={{ 'query': searchText }}>
                            <Button>Search</Button>
                        </Link>
                    </HStack>
                }

            </Box >
        </>
    )
}
